
import { defineComponent } from "vue";
import SinglePageLayout from "@/views/frontend/components/SinglePageLayout.vue";

export default defineComponent({
  name: "terms-of-use",
  components: { SinglePageLayout },
  created() {
    //Set page title
    document.title = "Terms of Service and Use | " + process.env.VUE_APP_TITLE;
  },
});
